import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49')
];

export const server_loads = [0,8];

export const dictionary = {
		"/": [19],
		"/(privileged)/admin": [21,[2,4],[3]],
		"/(privileged)/admin/assessment-tools/form-validation": [22,[2,4,5],[3]],
		"/(privileged)/admin/assessment-tools/launch": [23,[2,4,5],[3]],
		"/(privileged)/admin/manage/educators": [24,[2,4,6],[3]],
		"/(privileged)/admin/manage/educators/[id]/classes": [25,[2,4,6],[3]],
		"/(privileged)/admin/manage/users": [26,[2,4,6],[3]],
		"/(privileged)/admin/roster": [27,[2,4],[3]],
		"/(privileged)/educator": [28,[2,7],[3]],
		"/(privileged)/educator/resources": [29,[2,7,8],[3]],
		"/(privileged)/educator/resources/[slug]": [30,[2,7,8],[3]],
		"/(privileged)/educator/results": [31,[2,7,9],[3]],
		"/(privileged)/educator/results/[id]": [32,[2,7,9,10],[3]],
		"/(privileged)/educator/results/[id]/export": [33],
		"/exam/[examPlanId]/confirmation": [40,[11],[,12]],
		"/exam/[examPlanId]/summary": [41,[11],[,12]],
		"/exam/[examPlanId]/(game)/transition/[progressionIndex]/proctor": [38,[11,,13,17],[,12]],
		"/exam/[examPlanId]/(game)/transition/[progressionIndex]/student": [39,[11,,13,17],[,12]],
		"/exam/[examPlanId]/(game)/[resultId]/[taskItemsIndex]/[taskItemLabel]/proctor": [36,[11,,13,14,16],[,12,,15]],
		"/exam/[examPlanId]/(game)/[resultId]/[taskItemsIndex]/[taskItemLabel]/student": [37,[11,,13,14,16],[,12,,15]],
		"/(privileged)/landing": [34,[2],[3]],
		"/lobby": [42],
		"/(privileged)/proctor": [35,[2],[3]],
		"/signin": [43],
		"/signout": [44],
		"/student-game": [45,[18]],
		"/switch-role": [46],
		"/test-assets": [47],
		"/themes/admin": [48],
		"/themes/student": [49],
		"/(privileged)/[...path]": [20,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';